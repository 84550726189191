import PropTypes from 'prop-types';

function ReadingBlock(props) {
    const { header, paragraph, main, variants = '' } = props;

    return (
        <div className={`flex flex-col z-10 text-center items-center max-w-md md:max-w-2xl ${variants}`}>
            <h2
                className={`text-center text-bold px-4 ${main
                    ? 'lg:text-8xl md:text-7xl text-6xl leading-13 pb-2'
                    : 'lg:text-6xl md:text-5xl text-4xl'} `}
            >
                {header}
            </h2>
            {paragraph && <p className="text-center whitespace-pre-line text-xl lg:max-w-xl">{paragraph}</p>}
        </div>
    );
}

ReadingBlock.propTypes = {
    header: PropTypes.string,
    paragraph: PropTypes.string,
    main: PropTypes.bool,
    variants: PropTypes.string
};

export default ReadingBlock;
