function StepCard(props) {
    const { head1, head2, image, imageSrcSet, altText, paragraph, additional, variant } = props;

    return (
        <div className={`flex flex-col rounded-lg p-10 justify-start items-center gap-2 ${variant}`}>
            <h3 className="text-7xl">{head1}</h3>
            <div className="flex shrink lg:h-[500px] h-[250px] align-center items-center">
                <img
                    className="py-5 object-scale-down lg:max-h-[500px] max-h-[250px] w-auto"
                    src={image}
                    srcSet={imageSrcSet}
                    alt={altText}
                />
            </div>
            <h4 className="text-4xl">{head2}</h4>
            <p className="md:text-lg text-sm">{paragraph}</p>
            {additional ? additional : null}
        </div>
    );
}

export default StepCard;
