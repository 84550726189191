import { useRef, useEffect } from 'react';
import { Sprite, Stage } from '@inlet/react-pixi';
import DividerWaveMask from 'Components/WaveFooter/WaveFooterMask';

import GradientImage from 'Images/pngs/footer-gradient.png';

const WaveFooterBackground = (props) => {
    const { variants = '', parentHeight, parentWidth } = props;
    const waveHeight = 40;
    const waveMask = useRef();
    const gradientRef = useRef();

    const imageHeight = 540;

    useEffect(
        () => {
            if (waveMask.current === null || gradientRef.current === null) return;
            gradientRef.current.mask = waveMask.current;
        },
        [ waveMask ]
    );

    return (
        <div className={`${variants}`}>
            <Stage width={parentWidth} height={parentHeight} options={{ backgroundAlpha: 0 }} className="">
                <Sprite
                    image={GradientImage}
                    scale={{ x: parentWidth, y: parentHeight / imageHeight }}
                    ref={gradientRef}
                />
                <DividerWaveMask
                    width={parentWidth}
                    height={waveHeight}
                    stageHeight={parentHeight}
                    density={5}
                    ref={waveMask}
                />
            </Stage>
        </div>
    );
};

export default WaveFooterBackground;
