import { useEffect, useRef, useState, memo } from 'react';
import { Container, Sprite, Stage } from '@inlet/react-pixi';

import TitleMobile from 'Images/svgs/title-mobile.svg';
import TitleDesktop from 'Images/svgs/title-desktop.svg';
import { useWindowSize } from '@react-hook/window-size';

import WaveMask from 'Components/Title/WaveTitleMask';

import TitleSplashMobile from 'Images/pngs/title-desktop-splash@3x.png';
import TitleDropCenter from 'Images/pngs/title-drops-center@3x.png';
import TitleDropLeft from 'Images/pngs/title-drops-left@3x.png';
import TitleDropRight from 'Images/pngs/title-drops-right@3x.png';

const paddingY = 0;

const imageDataMobile = {
    textureData: {
        heightRatio: 1.71714286,
        width: 700,
        height: 1324
    },
    title: {
        image: TitleMobile
    },
    splash: {
        image: TitleSplashMobile,
        x: -10,
        y: 600,
        scale: 1.2
    },
    dropCenter: {
        image: TitleDropCenter,
        x: 200,
        y: 680,
        scale: 0.5
    },
    dropLeft: {
        image: TitleDropLeft,
        x: -350,
        y: -70,
        scale: 0.45
    },
    dropRight: {
        image: TitleDropRight,
        x: 350,
        y: 70,
        scale: 0.45
    }
};
const imageDataDesktop = {
    textureData: {
        heightRatio: 0.40267708,
        width: 3586,
        height: 1444
    },
    title: {
        image: TitleDesktop
    },
    splash: {
        image: TitleSplashMobile,
        x: -20,
        y: 35,
        scale: 2.2
    },
    dropCenter: {
        image: TitleDropCenter,
        x: 290,
        y: 280,
        scale: 0.8
    },
    dropLeft: {
        image: TitleDropLeft,
        x: -1750,
        y: 700,
        scale: 0.8
    },
    dropRight: {
        image: TitleDropRight,
        x: 1800,
        y: -380,
        scale: 0.8
    }
};

const TitleAnimation = memo((props) => {
    const { onWidthChange } = props;

    const [ paddingX, setPaddingX ] = useState(0);

    const waveMask = useRef();
    const spriteRef = useRef();

    const [ windowWidth ] = useWindowSize();

    const [ parentWidth, setParentWidth ] = useState(0);
    const [ parentHeight, setParentHeight ] = useState(0, 100);
    const [ spriteScale, setSpriteScale ] = useState(1);
    const [ visible, setVisible ] = useState(true);

    const [ spriteData, setSpriteData ] = useState(
        window.innerWidth < 768 ? { ...imageDataMobile } : { ...imageDataDesktop }
    );

    useEffect(
        () => {
            const isMobile = windowWidth < 768 ? true : false;
            setSpriteData(isMobile ? { ...imageDataMobile } : { ...imageDataDesktop });
            setPaddingX(isMobile ? 0 : 140);
            setVisible(false);
            recalculateDimensions();
        },
        [ windowWidth ]
    );

    const recalculateDimensions = () => {
        const minWidth = Math.min(1920, windowWidth);
        const realHeight = Math.floor(minWidth * spriteData.textureData.heightRatio);
        setParentWidth(Math.min(1920, minWidth));
        setParentHeight(realHeight);
        setVisible(true);
    };

    useEffect(
        () => {
            if (waveMask.current === null || spriteRef.current === null) return;
            spriteRef.current.mask = waveMask.current;
        },
        [ waveMask, spriteRef ]
    );

    useEffect(
        () => {
            recalculateDimensions();
        },
        [ spriteData ]
    );

    useEffect(
        () => {
            if (spriteRef.current === null) return;
            const ratio = Math.min(
                parentWidth / (spriteData.textureData.width + paddingX),
                parentHeight / (spriteData.textureData.height + paddingY + 110)
            );
            // console.log('parentWidth:', parentWidth);
            // console.log('texture.width:', spriteData.textureData.width);
            // console.log('texture.width:', spriteData.textureData.height);
            // console.log('paddingX:', paddingX);

            // console.log('ratio:', ratio);
            setSpriteScale(ratio);

            onWidthChange((spriteData.textureData.width + 30) * ratio);
        },
        [ parentWidth, parentHeight, spriteData, paddingX ]
    );

    return (
        <div className="flex relative w-full overflow-visible">
            <Stage width={parentWidth} height={parentHeight} options={{ backgroundAlpha: 0 }}>
                <Container
                    x={parentWidth / 2}
                    y={parentHeight / 2 - spriteScale * 50}
                    visible={visible}
                    scale={spriteScale}
                >
                    <Sprite {...spriteData.splash} anchor={0.5} />
                    <Sprite {...spriteData.title} anchor={0.5} tint={0x006dff} />
                    <Sprite {...spriteData.title} anchor={0.5} tint={0x9e80f2} ref={spriteRef} />
                    <Sprite {...spriteData.dropCenter} anchor={0.5} />
                    <Sprite {...spriteData.dropLeft} anchor={0.5} />
                    <Sprite {...spriteData.dropRight} anchor={0.5} />
                </Container>

                <WaveMask width={parentWidth} height={parentHeight} gap={20} density={5} ref={waveMask} />
            </Stage>
            <div className="block absolute h-full w-full opacity-0" />
        </div>
    );
});

export default TitleAnimation;
