import { useRef, useEffect, useState } from 'react';
import { useWindowResize } from 'beautiful-react-hooks';
import { Stage, Sprite } from '@inlet/react-pixi';
import DividerWaveMask from 'Components/WaveDivider/DividerWaveMask';
import GradientStrip from 'Images/jpgs/water-wave-bar.jpg';

const stageHeight = 100;

const WaveDivider = (props) => {
    const { variants = '' } = props;
    const waveMask = useRef();
    const spriteRef = useRef();

    const [ stageWidth, setStageWidth ] = useState(window.innerWidth);

    useWindowResize((e) => {
        setStageWidth(window.innerWidth);
    });

    useEffect(
        () => {
            if (waveMask.current === null || spriteRef.current === null) return;
            spriteRef.current.mask = waveMask.current;
        },
        [ waveMask, spriteRef ]
    );
    // NOTE: need to set a cap on the framerate (too high right now)
    return (
        <div className={`flex relative w-screen left-1/2 -translate-x-1/2 ${variants}`}>
            <Stage width={stageWidth} height={stageHeight} options={{ backgroundAlpha: 0 }} className="">
                <Sprite
                    image={GradientStrip}
                    width={stageWidth + 4}
                    height={stageHeight}
                    // scale={{ x: 0, y: 0.6 }}
                    anchor={0.5}
                    y={stageHeight / 2}
                    x={stageWidth / 2 - 2}
                    ref={spriteRef}
                />
                <DividerWaveMask width={stageWidth} height={stageHeight} gap={25} density={4} ref={waveMask} />
            </Stage>
            <div className={`block absolute w-full opacity-0`} style={{ height: stageHeight }} />
        </div>
    );
};

export default WaveDivider;
