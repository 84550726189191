import { forwardRef, useCallback, useState, useEffect } from 'react';
import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Graphics, useTick } from '@inlet/react-pixi';

const animObj = { y: 0 };
const tlTitleScrub = gsap.timeline();

const WaveTitleMask = forwardRef((props, ref) => {
    const { width, height, density } = props;
    const amplitude = 10;
    const frequency = 70;

    const [ timeOffset, setTimeOffset ] = useState(0);
    useTick((delta) => {
        setTimeOffset(timeOffset + 0.03 * delta);
    });

    useEffect(
        () => {
            tlTitleScrub.clear();
            tlTitleScrub.fromTo(
                animObj,
                {
                    y: 1
                },
                {
                    y: 0,
                    scrollTrigger: {
                        trigger: '#Top',
                        start: 'top+=1 top',
                        endTrigger: '#HeaderSection',
                        end: '80% top+=3',
                        // markers: true,
                        scrub: 1
                    }
                }
            );
        },
        [ height, width ]
    );

    const drawWave = useCallback(
        (g) => {
            let x = -density;
            let y = 0;
            let wHeight = animObj.y * height + 10;
            g.clear();
            g.beginFill(0x000000);
            //upper half
            g.moveTo(0, height + 20);
            g.lineTo(0, wHeight);
            while (x < width + 10) {
                y = wHeight + amplitude * Math.sin(x / frequency + timeOffset);
                x = x + density;
                g.lineTo(x, y);
            }
            g.lineTo(width + 10, wHeight);
            g.lineTo(width + 10, height + 20);
            g.endFill();
        },
        [ timeOffset, density, height, width ]
    );

    return <Graphics draw={drawWave} ref={ref} />;
});

export default WaveTitleMask;
