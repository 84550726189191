import Section from 'Components/Section';
import ReadingBlock from 'Components/ReadingBlock';
import StepCard from 'Components/StepCard';

import Step1Img from 'Images/pngs/step1.png';
import Step1Img2x from 'Images/pngs/step1@2x.png';
import Step1Img3x from 'Images/pngs/step1@3x.png';

import Step2Img from 'Images/pngs/step2.png';
import Step2Img2x from 'Images/pngs/step2@2x.png';
import Step2Img3x from 'Images/pngs/step2@3x.png';

import Step3Img from 'Images/pngs/step3.png';
import Step3Img2x from 'Images/pngs/step3@2x.png';
import Step3Img3x from 'Images/pngs/step3@3x.png';

import Step4Img from 'Images/pngs/step4.png';
import Step4Img2x from 'Images/pngs/step4@2x.png';
import Step4Img3x from 'Images/pngs/step4@3x.png';

import Step5Img from 'Images/pngs/step5.png';
import Step5Img2x from 'Images/pngs/step5@2x.png';
import Step5Img3x from 'Images/pngs/step5@3x.png';

import Step6Img from 'Images/pngs/step6.png';
import Step6Img2x from 'Images/pngs/step6@2x.png';
import Step6Img3x from 'Images/pngs/step6@3x.png';

function Steps() {
    return (
        <Section variants="gap-10 items-center" id="HowSection">
            <ReadingBlock main header="How to donate a thirst trap" paragraph="(It's really easy, we promise.)" />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:max-w-7xl px-4">
                {/* this will be the step card flex wrapper */}
                <StepCard
                    variant="bg-gradient-to-br from-card-purple to-card-pink"
                    head1="Step 01"
                    image={Step1Img}
                    imagesrcset={`${Step1Img2x} 2x, ${Step1Img3x} 3x`}
                    head2="Snap a selfie"
                    altText="Snap a selfie"
                    paragraph="Open Instagram Stories and snap your masterpiece, or choose an existing photo from your camera roll. It can be funny, sexy, self-aware, or whatever you think is going to trap the most thirst."
                />
                <StepCard
                    variant="bg-gradient-to-br from-card-skin to-card-purple"
                    head1="Step 02"
                    image={Step2Img}
                    imagesrcset={`${Step2Img2x} 2x, ${Step2Img3x} 3x`}
                    head2="Swipe up"
                    altText="Swipe up"
                    paragraph="And add an Instagram donation sticker to your photo in Instagram Stories. (Donation stickers are currently available in the US, UK, and other select markets.)"
                />
                <StepCard
                    variant="bg-gradient-to-br from-card-blue to-card-pink"
                    head1="Step 03"
                    image={Step3Img}
                    imagesrcset={`${Step3Img2x} 2x, ${Step3Img3x} 3x`}
                    head2="Choose Thirst Project"
                    altText="Choose Thirst Project"
                    paragraph="Select Thirst Project’s verified Instagram account as the donation recipient (look for the blue check to be sure)."
                />
                <StepCard
                    variant="bg-gradient-to-br from-card-blue to-high-purple"
                    head1="Step 04"
                    image={Step4Img}
                    imagesrcset={`${Step4Img2x} 2x, ${Step4Img3x} 3x`}
                    head2="Edit the text"
                    altText="Edit the text"
                    paragraph={`Update the text in the Donation Sticker to say “Thirst Traps for Thirst”.`}
                />
                <StepCard
                    variant="bg-gradient-to-br from-card-pink to-card-red"
                    head1="Step 05"
                    image={Step5Img}
                    imagesrcset={`${Step5Img2x} 2x, ${Step5Img3x} 3x`}
                    head2="Tag @Thirstproject"
                    altText="Tag @Thirstproject"
                    paragraph="Add a caption explaining why this issue matters to you, and tag us @ThirstProject so we can re-post and cheer you on!"
                />
                <StepCard
                    variant="bg-gradient-to-br from-card-pink to-card-teal"
                    head1="Step 06"
                    image={Step6Img}
                    imagesrcset={`${Step6Img2x} 2x, ${Step6Img3x} 3x`}
                    head2="Post"
                    altText="Post"
                    paragraph="Share your story and challenge friends to give what they can, or share their own Thirst Trap to contribute to the cause. Every dollar donated gets us one step closer to ending thirst."
                />
            </div>
        </Section>
    );
}

export default Steps;
