import Section from 'Components/Section';
import ReadingBlock from 'Components/ReadingBlock';
import IntroAnim from 'Components/IntroAnim';

function Intro(props) {
    return (
        <Section variants="relative gap-12 items-center px-12" id="WhatSection">
            <IntroAnim />
            <ReadingBlock
                main
                header="Turn social media thirst into clean drinking water."
                paragraph="785 million people in the world live without access to clean drinking water. Your thirst traps can help."
            />
            <ReadingBlock
                header="What's a thirst trap?"
                paragraph="When someone shares a sexy selfie on social media to attract attention, that's a thirst trap. We're not judging! Our goal is to harness that attention for a good cause: to help end the global water crisis."
            />
            <ReadingBlock
                variants={'pb-24'}
                header="Okay, but how?"
                paragraph="Thirst Traps for Thirst uses Instagram Donation Stickers to turn any thumb-stopping, eyeball-popping photo into a fundraiser for clean drinking water. All proceeds are donated directly to Thirst Project, an organization that has provided clean drinking water to more than 500,000 people around the world."
            />
        </Section>
    );
}

export default Intro;
