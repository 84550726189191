import { useRef } from 'react';
import { useWindowWidth } from '@react-hook/window-size';

import { ReactComponent as LogoMobile } from 'Images/svgs/footer-logo-mobile.svg';
import Button from 'Components/Button';
import WaveFooterBackground from 'Components/WaveFooter/WaveFooterBackground';
import { useResizeObserver } from 'beautiful-react-hooks';

function Footer(props) {
    const parentWidth = useWindowWidth();
    const ref = useRef();
    const DOMRect = useResizeObserver(ref);

    return (
        <div className="relative">
            <WaveFooterBackground
                variants="absolute block t-0 l-0 w-full h-auto z-0"
                parentWidth={parentWidth}
                parentHeight={DOMRect ? DOMRect.height : 600}
            />
            <div className="flex flex-col relative px-6 divide-y z-10 items-center" ref={ref}>
                <div className="flex flex-col w-full max-w-[1760px]">
                    <div className="flex flex-col w-full md:py-40 pt-32 py-20 px-2 items-center">
                        <h2 className=" text-high-pink text-9xl pb-5">Let's end thirst.</h2>
                        <Button variants="w-full px-4 align-center sm:w-auto">
                            <h3 className="text-3xl text-high-pink">#thirsttrapsforthirst</h3>
                        </Button>
                    </div>

                    <div className="flex flex-col w-full pt-8 pb-8 text-high-pink gap-5 justify-evenly md:flex-row md:justify-between border-t-high-pink border-t">
                        <LogoMobile className="w-[161px] h-full max-h-[21px]" />
                        <ul className="flex flex-col md:flex-row flex-wrap gap-x-5 tracking-tighter text-sm pt-0.5">
                            <li>&copy; 2021 All rights reserved</li>
                            <li>#thirsttrapsforthirst</li>
                            <li>Thirst Project</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
