import Button from './Button';
import { gsap } from 'gsap';

const DesktopNavigation = () => {
    const navClickHandler = (target) => {
        gsap.to(window, { duration: 2, scrollTo: { y: target, offsetY: 75 }, ease: 'power2.inOut' });
    };

    return (
        <nav className="flex flex-row text-center gap-10 items-center">
            <div className="cursor-pointer" onClick={(e) => navClickHandler('#WhatSection')}>
                <h5 className="tracking-wider text-xl">What</h5>
            </div>
            <div className="cursor-pointer" onClick={(e) => navClickHandler('#HowSection')}>
                <h5 className="tracking-wider text-xl">How</h5>
            </div>
            <div className="cursor-pointer" onClick={(e) => navClickHandler('#WhySection')}>
                <h5 className="tracking-wider text-xl">Why</h5>
            </div>

            <Button className="w-full px-4 align-center md:w-auto">
                <h5 className="tracking-wider text-xl px-2">#thirsttrapsforthirst</h5>
            </Button>
        </nav>
    );
};

export default DesktopNavigation;
