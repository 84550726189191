import { Fragment, useState, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useWindowResize } from 'beautiful-react-hooks';
import { gsap } from 'gsap';

import { ReactComponent as LogoMobile } from 'Images/svgs/corner-logo-mobile.svg';

import Button from './Button';

function MobileNavigation(props) {
    const [ open, setOpen ] = useState(false);
    const [ isMobile, setIsMobile ] = useState(false);

    const navClickHandler = (target) => {
        setOpen(false);
        gsap.to(window, { duration: 2, scrollTo: { y: target, offsetY: 75 }, ease: 'power2.inOut' });
    };

    useWindowResize((e) => {
        setIsMobile(window.innerWidth < 768 ? true : false);
    });

    useEffect(
        () => {
            if (open && isMobile) {
                // document.getElementById('Top').scrollIntoView();
                disableBodyScroll(document.body);
            } else if (!open && isMobile) {
                enableBodyScroll(document.body);
            }

            return () => {
                clearAllBodyScrollLocks(document.body);
            };
        },
        [ open, isMobile ]
    );

    return (
        <Fragment>
            <div className={`fixed top-0 left-0 w-full transition-all ${open ? 'h-full' : 'h-0'} overflow-hidden z-30`}>
                <div
                    className={`absolute top-0 left-0 flex flex-col bg-blue text-high-pink min-h-screen w-full p-6 transition-transform`}
                >
                    <nav className="flex flex-col text-center w-full text-5xl flex-grow justify-center gap-5 ">
                        <div className="cursor-pointer" onClick={(e) => navClickHandler('#WhatSection')}>
                            <h5 className="tracking-wider">What</h5>
                        </div>
                        <div className="cursor-pointer" onClick={(e) => navClickHandler('#HowSection')}>
                            <h5 className="tracking-wider">How</h5>
                        </div>
                        <div className="cursor-pointer" onClick={(e) => navClickHandler('#WhySection')}>
                            <h5 className="tracking-wider">Why</h5>
                        </div>

                        <Button>
                            <h5 className="tracking-wider text-4xl pt-1">#thirsttrapsforthirst</h5>
                        </Button>
                    </nav>
                </div>
            </div>
            <LogoMobile
                className="w-[161px] translate-y-1/2 self-start z-40 transition-all"
                style={{ fill: open ? '#EBB9F3' : '#006DFF' }}
            />
            <button
                className={`group flex flex-col justify-around relative w-11 h-11 p-2 md:hidden  transition ease-in-out duration-200 rounded-lg z-40 bg-high-pink`}
                onClick={(e) => setOpen(!open)}
            >
                <div
                    className={`block h-1 rounded-full transition ease-in-out duration-500 origin-left ${open
                        ? 'bg-white group-focus:bg-blue w-full rotate-[45deg] translate-x-[0.28rem] -translate-y-[0.03rem]'
                        : 'bg-blue group-focus:bg-white w-full'}`}
                />
                <div
                    className={`block h-1 rounded-full transition-all ease-in-out duration-500 origin-left ${open
                        ? 'bg-white group-focus:bg-blue w-0 opacity-0 translate-x-[0.28rem]'
                        : 'bg-blue group-focus:bg-white w-full'}`}
                />
                <div
                    className={`block bg-blue h-1 rounded-full transition ease-in-out duration-500 origin-left ${open
                        ? 'bg-white group-focus:bg-blue w-full -rotate-[45deg] translate-x-[0.28rem] translate-y-[0.03rem]'
                        : 'bg-blue group-focus:bg-white w-3/4'}`}
                />
            </button>
        </Fragment>
    );
}

export default MobileNavigation;
