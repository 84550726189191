import Section from 'Components/Section';
import ReadingBlock from 'Components/ReadingBlock';
import ReadingCard from 'Components/ReadingCard';

import level1DonationImage from 'Images/pngs/level1_donation.png';
import level1DonationImage2x from 'Images/pngs/level1_donation@2x.png';
import level1DonationImage3x from 'Images/pngs/level1_donation@3x.png';

import level2DonationImage from 'Images/pngs/level2_donation.png';
import level2DonationImage2x from 'Images/pngs/level2_donation@2x.png';
import level2DonationImage3x from 'Images/pngs/level2_donation@3x.png';

import level3DonationImage from 'Images/pngs/level3_donation.png';
import level3DonationImage2x from 'Images/pngs/level3_donation@2x.png';
import level3DonationImage3x from 'Images/pngs/level3_donation@3x.png';

function FAQ() {
    return (
        <Section variants="gap-10 items-center pb-20" id="WhySection">
            <div className="flex flex-col gap-2 items-center">
                <ReadingBlock main header="How your donations help" />
            </div>
            <div className="flex flex-col items-center md:items-start md:gap-0 gap-10 justify-evenly w-full md:flex-row max-w-xs sm:max-w-5xl">
                <ReadingCard
                    headerImage={level1DonationImage}
                    headerImageSet={`${level1DonationImage2x} 2x, ${level1DonationImage3x} 3x`}
                    headerImageAltText="$1"
                    paragraph="Every dollar provides two people with safe, clean drinking water for a year."
                />
                <ReadingCard
                    headerImage={level2DonationImage}
                    headerImageSet={`${level2DonationImage2x} 2x, ${level2DonationImage3x} 3x`}
                    headerImageAltText="$25"
                    paragraph="Is enough to give one person safe, clean drinking water for the rest of their life."
                />
                <ReadingCard
                    headerImage={level3DonationImage}
                    headerImageSet={`${level3DonationImage2x} 2x, ${level3DonationImage3x} 3x`}
                    headerImageAltText="$100"
                    paragraph="Gives an entire family of four access to safe, clean drinking water for life."
                />
            </div>
        </Section>
    );
}

export default FAQ;
