import { useEffect } from 'react';
import { gsap } from 'gsap';

import BGPhone1 from 'Images/pngs/bgphone1.png';
import BGPhone12x from 'Images/pngs/bgphone1@2x.png';
import BGPhone13x from 'Images/pngs/bgphone1@3x.png';
import BGPhone2 from 'Images/pngs/bgphone2.png';
import BGPhone22x from 'Images/pngs/bgphone2@2x.png';
import BGPhone23x from 'Images/pngs/bgphone2@3x.png';
import BGPhone3 from 'Images/pngs/bgphone3.png';
import BGPhone32x from 'Images/pngs/bgphone3@2x.png';
import BGPhone33x from 'Images/pngs/bgphone3@3x.png';

import Droplet from 'Images/pngs/droplet.png';
import Droplet2x from 'Images/pngs/droplet@2x.png';
import Droplet3x from 'Images/pngs/droplet@3x.png';

// TODO: https://greensock.com/docs/v3/Plugins/ScrollTrigger/static.batch()
// https://greensock.com/forums/topic/28761-nested-timelines-and-scrolltrigger/
// Use batch to create scrolltrigger?

const dropletIDTemplate = '#droplet-';

const phoneScale = 1.4;

const IntroAnim = () => {
    const animArray = [];
    const dropletCount = 10;

    useEffect(() => {
        const d = 3;
        const xDist = 1980;

        for (let j = 1; j <= dropletCount; j++) {
            const fadeDur = 0.25;
            const query = `${dropletIDTemplate}${j}`;
            const thisTL = gsap.timeline({ repeat: -1 });

            const calcX = j * xDist * 0.1 * 2;
            thisTL.set(query, { scale: Math.random() * 0.5 + 0.5 });
            thisTL.add([
                gsap.fromTo(query, { duration: fadeDur, opacity: 0 }, { opacity: Math.random() * 0.5 + 0.3 }),
                gsap.fromTo(
                    query,
                    {
                        x: -xDist + calcX,
                        y: 0
                    },
                    { duration: d, x: -xDist + calcX - 10, y: 600, ease: 'none' }
                )
            ]);
            thisTL.to(query, { duration: fadeDur, opacity: 0 }, `-=${fadeDur}`);

            const dur = thisTL.duration();
            thisTL.time(Math.random() * dur + j * 0.25);

            animArray.push(thisTL);
        }

        return () => {
            if (!animArray) return;
            animArray.forEach((cleanupTL) => {
                cleanupTL.kill();
                cleanupTL = null;
            });
        };
    }, []);

    const createDroplets = () => {
        const retArray = [];
        for (let i = 1; i <= dropletCount; i++) {
            retArray.push(
                <img
                    src={Droplet}
                    srcSet={`${Droplet2x} 2x, ${Droplet3x} 3x`}
                    width={67.5}
                    height={88}
                    alt="droplet"
                    className="block absolute left-1/2 top-0"
                    key={`droplet-${i}`}
                    id={`droplet-${i}`}
                />
            );
        }
        return retArray;
    };

    return (
        <div className="block absolute -top-10 w-full h-[calc(100%+90px)] overflow-x-[unset] invisible lg:visible">
            <div className="block absolute left-1/2 -translate-x-1/2">
                <img
                    src={BGPhone1}
                    srcSet={`${BGPhone12x} 2x, ${BGPhone13x} 3x`}
                    width={279 * phoneScale}
                    height={453.5 * phoneScale}
                    alt="bgphone1"
                    className="block relative lg:right-[500px] right-[50vw] top-[-40px]"
                />
            </div>
            <div className="block absolute left-1/2 -translate-x-1/2">
                <img
                    src={BGPhone2}
                    srcSet={`${BGPhone22x} 2x, ${BGPhone23x} 3x`}
                    width={279 * phoneScale}
                    height={453.5 * phoneScale}
                    alt="bgphone2"
                    className="block relative lg:left-[500px] left-[40vw] top-[150px]"
                />
            </div>
            <div className="block absolute bottom-0 left-1/2 -translate-x-[60%]">
                <img
                    src={BGPhone3}
                    srcSet={`${BGPhone32x} 2x, ${BGPhone33x} 3x`}
                    width={224 * phoneScale}
                    height={186 * phoneScale}
                    alt="bgphone3"
                    className="block relative lg:right-[400px] right-[40vw]"
                />
            </div>
            {createDroplets()}
        </div>
    );
};

export default IntroAnim;
