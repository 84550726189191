import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import WaveDivider from 'Components/WaveDivider/WaveDivider';

import Wrapper from 'Components/Wrapper';
import Header from 'Sections/01_Header';
import Intro from 'Sections/02_Intro';
import Steps from 'Sections/03_Steps';
import FAQ from 'Sections/04_FAQ';
import Footer from 'Sections/05_Footer';

import BGWaterMark from 'Images/jpgs/headerwatermark.jpg';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function App() {
    useEffect(() => {
        gsap.to('#Top', { opacity: 1, delay: 0.5 });
    }, []);

    return (
        <main
            className="flex flex-col relative opacity-0 w-full justify-center overflow-hidden selection:text-white selection:bg-blue/40"
            id="Top"
        >
            {/* this div is for the background */}
            <div className="flex flex-col absolute top-0 left-0 h-full w-full bg-high-pink overflow-x-hidden">
                <div
                    className="block h-screen w-full bg-cover bg-no-repeat bg-l-70-bottom sm:bg-bottom"
                    style={{ backgroundImage: `url(${BGWaterMark})` }}
                />
                <div className="block w-full h-full bg-gradient-to-b from-high-pink via-mid-pink to-high-pink" />
            </div>
            <Wrapper>
                <Header />
                <Intro />
                <WaveDivider variants={'pb-20'} />
                <Steps />
                <WaveDivider variants={'py-20'} />
                <FAQ />
            </Wrapper>
            <Footer />
        </main>
    );
}

export default App;
