function ReadingCard(props) {
    const { paragraph, headerImage, headerImageSet, headerImageAltText } = props;

    return (
        <div className="flex flex-col text-center items-center gap-5 max-w-[250px]">
            <img src={headerImage} srcSet={headerImageSet} alt={headerImageAltText} className="h-auto w-[100px]" />
            <p className="md:text-lg text-sm">{paragraph}</p>
        </div>
    );
}

export default ReadingCard;
