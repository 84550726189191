import { forwardRef, useCallback, useState } from 'react';

import { Graphics, useTick } from '@inlet/react-pixi';

const DividerWaveMask = forwardRef((props, ref) => {
    const { width, height, gap, density } = props;
    const amplitude = 8;
    const frequency = 70;

    const [ timeOffset, setTimeOffset ] = useState(0);
    useTick((delta) => {
        setTimeOffset(timeOffset + 0.03 * delta);
    });

    const drawWave = useCallback(
        (g) => {
            let x = 0;
            let y = 0;
            g.clear();
            g.beginFill(0x000000);

            //upper half
            g.moveTo(-3, height / 2);
            g.lineTo(-3, height / 2 - gap);
            while (x < width) {
                y = height / 2 + amplitude * Math.sin(x / frequency + timeOffset);
                x = x + density;
                g.lineTo(x, y - gap);
            }
            g.lineTo(width + 3, height / 2 - gap);
            g.lineTo(width + 3, height / 2);

            //lower half
            x = 0;
            y = 0;
            g.moveTo(-3, height / 2);
            g.lineTo(-3, height / 2 + gap);
            while (x < width) {
                y = height / 2 + amplitude * Math.sin(x / frequency + timeOffset);
                x = x + density;
                g.lineTo(x, y + gap);
            }
            g.lineTo(width + 3, height / 2 + gap);
            g.lineTo(width + 3, height / 2);

            g.endFill();
        },
        [ timeOffset, density, gap, height, width ]
    );

    return <Graphics draw={drawWave} ref={ref} />;
});

export default DividerWaveMask;
