import { forwardRef, useCallback, useState } from 'react';

import { Graphics, useTick } from '@inlet/react-pixi';

const WaveFooterMask = forwardRef((props, ref) => {
    const { width, height, stageHeight, density } = props;
    const amplitude = 8;
    const frequency = 75;

    const [ timeOffset, setTimeOffset ] = useState(0);
    useTick((delta) => {
        setTimeOffset(timeOffset + 0.03 * delta);
    });

    const drawWave = useCallback(
        (g) => {
            let x = -density;
            let y = 0;
            g.clear();
            g.beginFill(0x000000);
            //upper half
            g.moveTo(0, stageHeight);
            g.lineTo(0, height / 2);
            while (x < width) {
                y = height / 2 + amplitude * Math.sin(x / frequency + timeOffset);
                x = x + density;
                g.lineTo(x, y);
            }
            g.lineTo(width + 10, height / 2);
            g.lineTo(width + 10, stageHeight);
            g.endFill();
        },
        [ timeOffset, density, height, stageHeight, width ]
    );

    return <Graphics draw={drawWave} ref={ref} />;
});

export default WaveFooterMask;
