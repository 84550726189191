import { useState } from 'react';
import Section from 'Components/Section';
import MobileNavigation from 'Components/MobileNavigation';
import DesktopNavigation from 'Components/DesktopNavigation';
import TitleAnimation from 'Components/Title/TitleAnimation';

import { ReactComponent as LogoDesktop } from 'Images/svgs/corner-logo-desktop.svg';

import ThirstProjectLogo from 'Images/pngs/subhead.png';
import ThirstProjectLogo2x from 'Images/pngs/subhead@2x.png';
import ThirstProjectLogo3x from 'Images/pngs/subhead@3x.png';

function Header() {
    const [ navMaxWidth, setNavMaxWidth ] = useState(1284);

    return (
        <Section variants="py-6 px-0 justify-between items-center" id="HeaderSection">
            <div
                className="flex flex-row md:hidden grow-0 pb-6 px-2 z-10 w-full justify-between"
                style={{ maxWidth: navMaxWidth }}
            >
                <MobileNavigation />
            </div>

            <div
                className="hidden md:flex flex-row justify-center align-middle grow-0 pb-6 h-full max-h-[6rem] w-full"
                style={{ maxWidth: navMaxWidth }}
            >
                <div className="flex flex-row justify-between w-full">
                    <LogoDesktop className="w-[90px]" />
                    <DesktopNavigation />
                </div>
            </div>

            <TitleAnimation onWidthChange={(newWidth) => setNavMaxWidth(newWidth)} />
            <img
                src={ThirstProjectLogo}
                srcSet={`${ThirstProjectLogo2x} 2x, ${ThirstProjectLogo3x} 3x`}
                className="self-center px-5 pt-2 pb-3 md:pb-14 max-w-[400px] w-full h-auto grow-0"
                alt="A charitable challenge by thirst project"
            />
        </Section>
    );
}

export default Header;
