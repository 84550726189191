function Button(props) {
    const { children, variants = '' } = props;

    return (
        <button
            className={`flex justify-center align-middle items-center border-2 rounded-full pt-1 border-white hover:bg-white transition ease-in-out duration-200 ${variants}`}
        >
            {children}
        </button>
    );
}

export default Button;
