function Section(props) {
    const { children, variants = '', ...rest } = props;
    return (
        <div className={`flex flex-col px-6 justify-center w-auto text-center overflow-visible ${variants}`} {...rest}>
            {children}
        </div>
    );
}

export default Section;
